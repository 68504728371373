<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Map Feature
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Setting</li>          
          <li class="breadcrumb-item active" aria-current="page">Map Feature</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <b-modal
        :centered="true"
        :hide-footer="true"
        :title="titleModal"
        v-model="modalForm"
      >
        <b-row>
          <b-col class="mb-3" lg="12">
            <template v-if="moduleState == 'Component'">
              <b-row>
                <b-col lg="6"><label>Name</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <input type="text" v-model="form.name" class="form-control form-control-lg" placeholder="Name">
              <b-row>
                <b-col lg="6"><label>Remarks</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <textarea v-model="form.remarks" class="form-control form-control-lg"></textarea>
            </template>
            <template v-else-if="moduleState == 'Features'">
              <b-row>
                <b-col lg="6"><label>Name</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <input type="text" v-model="name_component" class="form-control form-control-lg" disabled="disabled">
              <b-row class="mt-2">
									<b-col lg="6"><label>Feature</label></b-col>
									<b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
								</b-row>
                <v-select v-model="optSelect1" :options="dataOption1"></v-select>              
              <b-row>
                <b-col lg="6"><label>Remarks</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <textarea v-model="form.remarks" class="form-control form-control-lg"></textarea>
            </template>
          </b-col> 
          <b-col class="mb-3 mb-xl-0" lg="12">
            <b-input-group>
              <b-button
                variant="primary"
                href="javascript:void(0)"
                @click="sendPayload()">Save
              </b-button>
            </b-input-group>
          </b-col>                
        </b-row>
      </b-modal>
      <div class="col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2 pb-5">
        <!-- <button type="button"  
          class="btn btn-sm btn-primary btn-icon-text pull-right"
          @click="openAddModal('Component','add',[])">
          Add Component
          <i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
        </button>         -->
      </div>

      <div class="col-lg-6 grid-margin stretch-card" v-for="(item, index) in data" :key="index">
				<div class="card">
          <div class="card-header">
						<div class="row col-lg-6 pull-left float-none float-sm-left d-block mt-3 mt-sm-1 text-left mr-1 pt-2 pl-2">
              <h3 class="page-title">Component [{{ item.name }}]</h3>
						</div>            
          </div>
					<div class="card-body">
              <div class="row col-lg-6 pull-left float-none float-sm-left d-block mt-3 mt-sm-1 mb-3 text-left mr-1 pt-2 pl-2">
                <h3 class="page-title">Features</h3>
              </div>                        
              <div class="row col-lg-6 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 mb-3 text-right mr-1 pt-2 pl-2">
                <button type="button"  
                  class="btn btn-sm btn-primary btn-icon-text"
                  @click="openAddModal('Features','add',item)">
                  Add Features
                  <i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
                </button>							
              </div>              
							<b-table 
							responsive 
							show-empty
							:striped="tableConfig.striped"
							:bordered="tableConfig.bordered"
							:borderless="tableConfig.borderless"
							:outlined="tableConfig.outlined"
							:small="tableConfig.small"
							:hover="tableConfig.hover"
							:dark="tableConfig.dark"
							:fixed="tableConfig.fixed"
							:foot-clone="tableConfig.footClone"
							:no-border-collapse="tableConfig.noCollapse"
							:head-variant="tableConfig.headVariant"
							:table-variant="tableConfig.tableVariant"
							:items="item.feature" 
							:fields="fields">
								<template #cell(index)="data">
									{{ data.index + 1 }}
								</template>						
								<template v-slot:cell(created_at)="row">
									{{ normalDateTime(row.item.created_at) }}
								</template>
								<template v-slot:cell(updated_at)="row">
									{{ normalDateTime(row.item.updated_at) }}
								</template>
								<template v-slot:cell(actions)="row">
                  <button type="button"  
                    class="btn btn-sm col-lg-6 mt-2 btn-warning btn-icon-text text-center"
                    @click="openEditModal('Features','edit',row.item.uuid, row.item)">
                    <i class="mdi mdi-file-check btn-icon-append"></i>
                  </button>

                  <button type="button"  
                    class="btn btn-sm col-lg-6 mt-2 btn-danger btn-icon-text text-center"
                    @click="openDeleteModal('Features',row.item)">
                    <i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
                  </button>

                  <!-- <button type="button"  
                    class="btn btn-sm col-lg-6 mt-2 btn-primary btn-icon-text text-center"
                    @click="openLink('detail',row.item)">
                    <i class="mdi mdi mdi-map btn-icon-append"></i>
                  </button>                   -->

								</template>							
							</b-table>
					</div>
				</div>
      </div>	
            
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <div class="col-lg-12 grid-margin stretch-card">
              <b-pagination
                v-model="page"
                :total-rows="totalRows"
                :per-page="limit"
                align="fill"
                size="md"
                class="mb-1 justify-content-md-center"
                v-on:click.native="callback"
              ></b-pagination>
            </div>			            
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",
      moduleState: "",			
			tableConfig : packageGlobals.table,
      data: [],
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      name_component : '',
      dataOption1: [],
      optSelect1: '',      
      form1: {
        uuid_schema_features : '',
        uuid_features : ''
      },
      form: {
				name: "",
        remarks: ""
      },						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'name_feature', label: 'Name', class: "text-center", sortable: true},
				{key: 'name', label: 'route', class: "text-center", sortable: true},
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },
    openLink: function (routeLink, item) {
      this.$router.push(`/setting/feature/${routeLink}/${item.uuid}`)      
    },    
    getAll: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/schema-features?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
			})				
			
    },
    getServices: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/setting/feature?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        // this.dataOption1 = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})							
    },    
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openAddModal: function (params,arg,item) {
      this.titleModal = `Add ${params}`
      this.stateCrud = arg
      this.moduleState = params
      this.modalForm = true
      if(params == 'Features')
      {
        this.name_component = item.name
        this.form1.uuid_schema_features = item.uuid 
        this.form1.uuid_features = ''
      }
      // else
      // {
      // }
    },
    openEditModal: function (params,arg,id, item) {
      this.titleModal = `Edit ${params}`
      this.stateCrud = arg
      this.moduleState = params      
      this.idKey = id
      if (params == 'Features') {
        this.name_component = item.name_schema_features
        this.form1.uuid_schema_features = item.uuid_schema_features
        this.form1.uuid_features = item.uuid_features        
      }
      else
      {
        this.form.name = item.name
        this.form.remarks = item.remarks
      }
      this.modalForm = true
    },
    openDeleteModal: function (params,item) {
      this.$swal.fire({
        title: `Are you sure you want to delete this ${params} data?`,
        // text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
				console.log(result.value);
        if (result.value) {
          if (params == 'Features') {
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
            axios.delete(`${packageStore.mainApi}/api/web/master/map-features/${item.uuid}/${localStorage.getItem("uuid")}`)
            .then((response) => {
              this.responseAxios(response)
            })            
          }
          else
          {
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
            axios.delete(`${packageStore.mainApi}/api/web/reference/schema-features/${item.uuid}/${localStorage.getItem("uuid")}`)
            .then((response) => {
              this.responseAxios(response)
            })
          }
        }
      })
    },		
    sendPayload: function () {
      if (this.stateCrud == 'add') {
        if (this.moduleState == 'Component') {
          this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/reference/schema-features`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/reference/schema-features`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })          
        }
        else
        {
          this.form1.uuid_features = this.optSelect1['id'] 
          this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/reference/schema-features`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/master/map-features`,this.form1)
          .then((response) => {
            this.responseAxios(response)
          })        
        }
      }
      else if (this.stateCrud == "edit") {
        if (this.moduleState == 'Component') {
          this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/reference/schema-features/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })          
        } else {
          this.form1.uuid_features = this.optSelect1['id'] 
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/master/map-features/${this.idKey}`,this.form1)
          .then((response) => {
            this.responseAxios(response)
          })          
        }
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)		
    this.getServices('-',1,999)    
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>